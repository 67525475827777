<template>
  <div slot="content" v-if="getUserLimits.fees">
    <div class="walletLimitsTable w-full shadow-component-wrapper-root" v-for="(provider, key) in filterCurrency()" :key="key">
      <!-- TODO: Remove hardcode chek if currency !== RUB -->
      <div class="wlt-head" v-if="key !== 'RUB'">
        <div class="wlt-item">{{ key }}</div>
        <div class="wlt-item">deposit</div>
        <div class="wlt-item">withdrawal</div>
        <div class="wlt-item">fees</div>
      </div>
      <div class="wlt-wrapper" v-for="(method, methodKey) in getUserLimits.rules[key]" :key="methodKey">
        <div class="wlt-body" v-if="enabledMethodHandler(getUserLimits.rules[key][methodKey])">
          <div class="wlt-col">
            <div class="wlt-item">
              <div class="wlt-item_icon" v-if="!methodKey == 'GATEWAY' || !methodKey == 'SEPA'">
                <SquareCoinIcon currency="default" />
              </div>
              <div class="wlt-item_iconGateway" v-if="methodKey == 'GATEWAY' && !isCrypto(key)">
                <img src="@/assets/img/landing/payments/VISA.svg" />
                <img src="@/assets/img/landing/payments/MC.svg" />
              </div>
              <div v-else-if="methodKey == 'GATEWAY' && isCrypto(key)">
                {{ key }}
              </div>
              <div v-else-if="methodKey == 'SEPA'" class="wlt-item_iconGateway">
                <img src="@/assets/img/landing/payments/SEPA.svg" />
              </div>
              <div v-else>{{ methodKey.split('_').join(' ') }}</div>
            </div>
          </div>
          <div class="wlt-col">
            <div class="wlt-item">
              <span>
                min: <b>{{ payLimitsHandler(getUserLimits.limits.deposit[key], { mkey: methodKey, mname: 'min_amount' }) }}</b>
              </span>
              <span>
                max: <b>{{ payLimitsHandler(getUserLimits.limits.deposit[key], { mkey: methodKey, mname: 'max_amount' }) }}</b>
              </span>
            </div>
          </div>
          <div class="wlt-col">
            <div class="wlt-item">
              <span>
                min: <b>{{ payLimitsHandler(getUserLimits.limits.withdrawal[key], { mkey: methodKey, mname: 'min_amount' }) }}</b>
              </span>
              <span>
                max: <b>{{ payLimitsHandler(getUserLimits.limits.withdrawal[key], { mkey: methodKey, mname: 'max_amount' }) }}</b>
              </span>
            </div>
          </div>
          <div class="wlt-col">
            <div class="wlt-item">
              <span>
                Deposit: <b>{{ payFeesHandler(getUserLimits.fees.deposit[key], { mkey: methodKey, currency: key }) }}</b>
              </span>
              <span>
                Withdrawal: <b>{{ payFeesHandler(getUserLimits.fees.withdrawal[key], { mkey: methodKey, currency: key }) }}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletFeesMethods } from '@/mixins/walletFeesMethods';
import { mapState } from 'vuex';
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

export default {
  components: {
    SquareCoinIcon,
  },
  data: () => ({ status: false, isCrypto }),
  mixins: [walletFeesMethods],
  computed: {
    ...mapState('user', ['userLimits', 'userFees', '_userModuleLoaded', 'userDepositRules']),
    getUserLimits() {
      if (this.status) {
        return { limits: this.userLimits, fees: this.userFees, rules: this.userDepositRules };
      }
      return {};
    },
  },
  watch: {
    _userModuleLoaded: {
      handler(newVal) {
        if (newVal) this.status = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import '../fees.scss';
</style>
